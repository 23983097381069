import { Component, Inject, OnInit, Optional, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  InvoiceDetailsComponent,
  DialogData,
} from "../../../invoices/invoice-details/invoice-details.component";
import * as moment from "moment";
import { AppService } from "../../../../services/helpers/app.service";
import { saveAs } from "file-saver";
import {
  LocalDataSource,
  Ng2SmartTableComponent,
  ServerDataSource,
} from "ng2-smart-table";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { DatePickerService } from "../../../../services/helpers/date-picker.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "ngx-download-report",
  templateUrl: "./download-report.component.html",
  styleUrls: ["./download-report.component.scss"],
})
export class DownloadReportComponent implements OnInit {
  currentDate = new Date();
  year = this.currentDate.getFullYear();
  month = this.currentDate.getMonth();
  today = moment(new Date()).format("yyyy-MM-DD");
  dateFrom: any;
  dateTo: any;
  showExport: boolean = false;
  userId = localStorage.getItem("userId");
  report: any;
  report_name: any;
  fileName = "";

  settings = {};
  tableData: LocalDataSource = new LocalDataSource();
  showTable: boolean = false;
  nodata = "No Data Found";
  rows: number = 10;
  smartTable: Ng2SmartTableComponent;
  showSpinner: boolean = false;
  data:any
  constructor(
    // @Optional() public dialogRef: MatDialogRef<InvoiceDetailsComponent>,
    // @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private appService: AppService,
    private http: HttpClient,
    private datePickerService: DatePickerService,
    private activatedRoute: ActivatedRoute

  ) {}

  ngOnInit(): void {
    this.data = this.activatedRoute.snapshot.queryParams;
    this.report = this.data["report"];
    this.report_name = this.data["report"] + " Report";
    this.getDate();
  }

  toggleMenu() {
    document.getElementById("dropdownmenu")?.classList.toggle("show-drop-down");
  }

  getDate() {
    if (
      this.data["report"] == "Unpaid" ||
      this.data["report"] == "Paid" ||
      this.data["report"] == "Delay" ||
      this.data["report"] == "Opened" ||
      this.data["report"] == "Contracts Expiring Soon" ||
      this.data["report"] == "Maintenance"
    ) {
      this.dateFrom = moment(new Date(this.year, this.month, 1)).format(
        "yyyy-MM-DD"
      );
      this.dateTo = moment(new Date(this.year, this.month + 1, 0)).format(
        "yyyy-MM-DD"
      );
    } else if (this.data["report"] == "Activity Log") {
      this.dateFrom = this.today;
      this.dateTo = this.today;
    }
  }

  search() {
    this.showSpinner = true;
    this.showTable = true;
    this.showExport = true;
    this.getDataFromApi();
  }

  reset() {
    this.dateTo = "";
    this.dateFrom = "";
    this.search();
  }

  // ********** start get data ********** //
  getSlug() {
    let slug = "";
    switch (this.report) {
      case "Unpaid":
        slug = `unpaid_report`;
        this.mergeSettings(this.unpaid_invoices_settings);
        this.formatUnpaid();
        break;
      case "Paid":
        slug = `payment_report`;
        this.mergeSettings(this.paid_invoices_settings);
        this.formatPaid();
        break;
      case "Delay":
        slug = `delayed_invoices_report`;
        this.mergeSettings(this.delay_invoices_settings);
        this.formatDelay();
        break;
      case "Opened":
        slug = `opening_unpaied_invoices_report`;
        this.mergeSettings(this.open_invoices_settings);
        this.formatOpened();
        break;
      case "Contracts Expiring Soon":
        slug = `expired_soon_contract_report`;
        this.mergeSettings(this.contracts_expiring_soon_settings);
        this.formatExpiring();
        break;
      case "Maintenance":
        slug = `maintenance_report`;
        this.mergeSettings(this.maintenance_settings);
        this.formatMaintenance();
        break;
      case "Activity Log":
        slug = `saveActivityLogs`;
        this.mergeSettings(this.activity_log_settings);
        this.formatActivity();
        break;
      default:
        break;
    }
    return slug;
  }

  getDataFromApi() {
    let slug = this.getSlug();
    slug =
      slug +
      `?company_id=${this.userId}&date_from=${this.dateFrom}&date_to=${this.dateTo}&view=true`;
    this.tableData = new ServerDataSource(this.http, {
      endPoint: `${environment.URL}${slug}`,
      dataKey: "data",
      pagerLimitKey: "per_page",
      pagerPageKey: "page",
      totalKey: "total",
    });
    this.checkForChanges();
  }

  checkForChanges() {
    this.tableData.onChanged().subscribe((changes) => {
      if (changes.action == "page") {
        this.datePickerService.changePage(changes.paging?.page);
        this.showSpinner = false;
      }
      if (changes.elements) {
        this.showSpinner = false;
        this.getSlug(); // to call format method after data coming from response.
      }
    });
  }
  // ********** end get data ********** //

  // ********** start columns settings and format data ********** //
  mergeSettings(chosenReport) {
    this.settings = Object.assign(this.addition_settings, chosenReport);
  }

  addition_settings = {
    pager: {
      display: true,
      perPage: this.rows,
    },
    actions: false,
    noDataMessage: this.nodata,
  };

  unpaid_invoices_settings = {
    columns: {
      payer_name: {
        title: "Payer Name",
        type: "text",
        filter: false,
      },
      payer_phone: {
        title: "Payer Phone",
        type: "text",
        filter: false,
      },
      unit: {
        title: "Unit",
        type: "text",
        filter: false,
      },
      collection_of_units: {
        title: "Collection Of Units",
        type: "text",
        filter: false,
      },
      due_pending: {
        title: "Due Pending",
        type: "text",
        filter: false,
      },
      contract_name: {
        title: "Contract Name",
        type: "text",
        filter: false,
      },
      contract_type: {
        title: "Contract Type",
        type: "text",
        filter: false,
      },
      contract_date_from: {
        title: "Contract Date From",
        type: "text",
        filter: false,
      },
      contract_date_to: {
        title: "Contract Date To",
        type: "text",
        filter: false,
      },
      created_on: {
        title: "Created On",
        type: "text",
        filter: false,
      },
    },
  };
  formatUnpaid() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].payer_name = data[i]?.payer?.name || "-";
      data[i].payer_phone =
        data[i]?.payer?.tel1 || data[i]?.payer?.mobile || "-";
      data[i].unit = data[i]?.contract?.unit_id || "-";
      data[i].collection_of_units = data[i]?.amount_total || "-";
      data[i].due_pending = data[i]?.amount_remaining || "-";
      data[i].contract_name = data[i]?.contract?.contract_name || "-";
      data[i].contract_type = data[i]?.contract?.service_type?.name || "-";
      data[i].contract_date_from = data[i]?.date_from || "-";
      data[i].contract_date_to = data[i]?.date_to || "-";
      data[i].created_on = data[i]?.created_on || "-";
    }
  }

  paid_invoices_settings = {
    columns: {
      payer_name: {
        title: "Payer Name",
        type: "text",
        filter: false,
      },
      payer_phone: {
        title: "Payer Phone",
        type: "text",
        filter: false,
      },
      unit: {
        title: "Unit",
        type: "text",
        filter: false,
      },
      contract_name: {
        title: "Contract Name",
        type: "text",
        filter: false,
      },
      contract_type: {
        title: "Contract Type",
        type: "text",
        filter: false,
      },
      amount: {
        title: "Amount",
        type: "text",
        filter: false,
      },
      payment_method: {
        title: "Payment Method",
        type: "text",
        filter: false,
      },
      created_on: {
        title: "Created On",
        type: "text",
        filter: false,
      },
      updated_on: {
        title: "Updated On",
        type: "text",
        filter: false,
      },
    },
  };
  formatPaid() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].payer_name = data[i]?.payer?.name || "-";
      data[i].payer_phone =
        data[i]?.payer?.tel1 || data[i]?.payer?.mobile || "-";
      data[i].unit = data[i]?.contract?.unit_id || "-";
      data[i].amount = data[i]?.amount_total || "-";
      data[i].payment_method =
        data[i]?.receipts[0]?.payment_method?.name || "-";
      data[i].contract_name = data[i]?.contract?.contract_name || "-";
      data[i].contract_type = data[i]?.contract?.service_type?.name || "-";
      data[i].created_on = data[i]?.created_on || "-";
      data[i].updated_on = data[i]?.updated_on || "-";
    }
  }

  delay_invoices_settings = {
    columns: {
      invoice_name: {
        title: "Invoice Name",
        type: "text",
        filter: false,
      },
      phone: {
        title: "Phone",
        type: "text",
        filter: false,
      },
      amount: {
        title: "Amount",
        type: "text",
        filter: false,
      },
      start_date: {
        title: "Start Date",
        type: "text",
        filter: false,
      },
      end_date: {
        title: "End Date",
        type: "text",
        filter: false,
      },
      created_on: {
        title: "Created On",
        type: "text",
        filter: false,
      },
    },
  };
  formatDelay() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].invoice_name = data[i]?.name || "-";
      data[i].phone = data[i]?.payer?.tel1 || data[i]?.payer?.mobile || "-";
      data[i].amount = data[i]?.amount_total || "-";
      data[i].start_date = data[i]?.date_from || "-";
      data[i].end_date = data[i]?.date_to || "-";
      data[i].created_on = data[i]?.created_on || "-";
    }
  }

  open_invoices_settings = {
    columns: {
      invoice_name: {
        title: "Invoice Name",
        type: "text",
        filter: false,
      },
      payer_name: {
        title: "Payer Name",
        type: "text",
        filter: false,
      },
      payer_phone: {
        title: "Payer Phone",
        type: "text",
        filter: false,
      },
      amount: {
        title: "Amount",
        type: "text",
        filter: false,
      },
      start_date: {
        title: "Start Date",
        type: "text",
        filter: false,
      },
      end_date: {
        title: "End Date",
        type: "text",
        filter: false,
      },
      created_on: {
        title: "Created On",
        type: "text",
        filter: false,
      },
      updated_on: {
        title: "Updated On",
        type: "text",
        filter: false,
      },
    },
  };
  formatOpened() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].invoice_name = data[i]?.name || "-";
      data[i].payer_name = data[i]?.payer?.name || "-";
      data[i].payer_phone =
        data[i]?.payer?.tel1 || data[i]?.payer?.mobile || "-";
      data[i].amount = data[i]?.amount_total || "-";
      data[i].start_date = data[i]?.date_from || "-";
      data[i].end_date = data[i]?.date_to || "-";
      data[i].created_on = data[i]?.created_on || "-";
      data[i].updated_on = data[i]?.updated_on || "-";
    }
  }

  contracts_expiring_soon_settings = {
    columns: {
      contract_name: {
        title: "Contract Name",
        type: "text",
        filter: false,
      },
      payer_name: {
        title: "Payer Name",
        type: "text",
        filter: false,
      },
      contract_amount: {
        title: "Contract Amount",
        type: "text",
        filter: false,
      },
      contract_status: {
        title: "Contract Status",
        type: "text",
        filter: false,
      },
      frequency: {
        title: "Frequency",
        type: "text",
        filter: false,
      },
      start_date: {
        title: "Start Date",
        type: "text",
        filter: false,
      },
      end_date: {
        title: "End Date",
        type: "text",
        filter: false,
      },
      collection_date: {
        title: "Collection Date",
        type: "text",
        filter: false,
      },
      created_on: {
        title: "Created On",
        type: "text",
        filter: false,
      },
    },
  };
  formatExpiring() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].contract_name = data[i]?.contract_name || "-";
      data[i].payer_name = data[i]?.secondparty?.name || "-";
      data[i].contract_amount = data[i]?.amount || "-";
      data[i].contract_status = data[i]?.active == "1" ? "Active" : "Not Active";
      data[i].frequency = data[i]?.frequncy || "-";
      data[i].start_date = data[i]?.start_date || "-";
      data[i].end_date = data[i]?.end_date || "-";
      data[i].collection_date = data[i]?.start_collection_date || "-";
      data[i].created_on = data[i]?.created_on || "-";
    }
  }

  maintenance_settings = {
    columns: {
      contract_name: {
        title: "Contract Name",
        type: "text",
        filter: false,
      },
      tenant_name: {
        title: "Tenant Name",
        type: "text",
        filter: false,
      },
      request_date: {
        title: "Request Date",
        type: "text",
        filter: false,
      },
      tenant_phone: {
        title: "Tenant Phone",
        type: "text",
        filter: false,
      },
      unit_no: {
        title: "Unit No",
        type: "text",
        filter: false,
      },
      maintenance_type: {
        title: "Maintenance Type",
        type: "text",
        filter: false,
      },
      company_description: {
        title: "Company Description",
        type: "text",
        filter: false,
      },
      company_comments: {
        title: "Company Comments",
        type: "text",
        filter: false,
      },
      expected_cost: {
        title: "Expected Cost",
        type: "text",
        filter: false,
      },
      actual_cost: {
        title: "Actual Cost",
        type: "text",
        filter: false,
      },
      date_viewed: {
        title: "Date Viewed",
        type: "text",
        filter: false,
      },
      auth_by: {
        title: "Auth By",
        type: "text",
        filter: false,
      },
      priority: {
        title: "Priority",
        type: "text",
        filter: false,
      },
    },
  };
  formatMaintenance() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].contract_name = data[i]?.unit?.contracts[0]?.contract_name || "-";
      data[i].tenant_name = data[i]?.payer?.name || "-";
      data[i].request_date = data[i]?.created_on || "-";
      data[i].tenant_phone =
        data[i]?.payer?.tel1 || data[i]?.payer?.mobile || "-";
      data[i].unit_no = data[i]?.unit?.unit_no || "-";
      data[i].maintenance_type = data[i]?.name || "-";
      data[i].company_description = data[i]?.company_description || "-";
      data[i].company_comments = data[i]?.company_comments || "-";
      data[i].expected_cost = data[i]?.expected_cost || "-";
      data[i].actual_cost = data[i]?.actual_cost || "-";
      data[i].date_viewed = data[i]?.date_viewed || "-";
      data[i].auth_by = data[i]?.receiver?.name || "-";
      data[i].priority = data[i]?.priority || "-";
    }
  }

  activity_log_settings = {
    columns: {
      date: {
        title: "Date",
        type: "text",
        filter: false,
      },
      user_id: {
        title: "User ID",
        type: "text",
        filter: false,
      },
      user_type: {
        title: "User Type",
        type: "text",
        filter: false,
      },
      user_name: {
        title: "User Name",
        type: "text",
        filter: false,
      },
      subject_id: {
        title: "Subject ID",
        type: "text",
        filter: false,
      },
      log_name: {
        title: "Log Name",
        type: "text",
        filter: false,
      },
      subject_type: {
        title: "Subject Type",
        type: "text",
        filter: false,
      },
      ip: {
        title: "IP",
        type: "text",
        filter: false,
      },
      browser: {
        title: "Browser",
        type: "text",
        filter: false,
      },
    },
  };
  formatActivity() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].log_name = data[i]?.log_name || "-";
      data[i].user_id = data[i]?.causer_id || "-";
      data[i].user_type = data[i]?.causer_type || "-";
      let desc = data[i]?.description?.split(",");
      data[i].user_name = this.formatText(desc[1]) || "-";
      data[i].ip = this.formatText(desc[2]) || "-";
      data[i].browser = this.formatText(desc[3]) || "-";
      data[i].subject_type = data[i]?.subject_type || "-";
      data[i].subject_id = data[i]?.subject_id || "-";
      data[i].date = data[i]?.created_at || "-";
    }
  }

  formatText(text) {
    const regex = /\(([^)]+)\)/;
    const matches = text.match(regex);
    return (text = matches[1]);
  }
  // ********** end columns settings and format data ********** //

  // ********** start download reports ********** //
  downloadExcel() {
    let slug = this.getSlug();
    let fileName = `${this.data["report"]}_${this.today}`;
    slug =
      this.dateFrom || this.dateTo
        ? slug +
          `?company_id=${this.userId}&date_from=${this.dateFrom}&date_to=${this.dateTo}`
        : slug + `?company_id=${this.userId}`;
    this.appService.openUrlWithToken(slug).subscribe((res) => {
      saveAs(res, fileName);
    });
  }
  downloadPdf() {
    let slug = this.getSlug();
    let fileName = `${this.data["report"]}_${this.today}`;
    slug =
      this.dateFrom || this.dateTo
        ? slug +
          `?company_id=${this.userId}&date_from=${this.dateFrom}&date_to=${this.dateTo}&pdf=true`
        : slug + `?company_id=${this.userId}&pdf=true`;
    this.appService.openUrlWithToken(slug).subscribe((res) => {
      saveAs(res, fileName);
    });
  }
  // ********** end download reports ********** //
}
