<span class="parent d-flex justify-content-end align-items-center nowrap"
  >Showing rows <span class="num">{{ currentPage }}</span> to
  <span class="num mx-3">
    <ng-select
      [clearable]="false"
      [(ngModel)]="rowsNumber"
      (change)="changePage($event)"
      fullwidth
      class="select-role"
      placeholder="{{ 'Select Page' | translate }}"
    >
      <ng-option [value]="5">{{ 5 }}</ng-option>
      <ng-option [value]="10">{{ 10 }}</ng-option>
      <ng-option [value]="20">{{ 20 }}</ng-option>
      <ng-option [value]="50">{{ 50 }}</ng-option>
      <ng-option [value]="100">{{ 100 }}</ng-option>
    </ng-select>
  </span>
  of <span class="num">{{ totalPages }}</span></span
>
