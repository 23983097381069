<nb-card class="card">
  <nb-card-header class="header px-2" [dir]=" 'ltr'">
    <div class="row">
      <div class="col-sm-12 text-center mb-3">
        <h5 class="c-logo-blue custom-heeder">
          {{ report_name | translate }}
        </h5>
      </div>
    </div>
    <div class="row pb-3">
      <div class="row col-md-6 right m-0 p-0">
        <div class="col-sm-6">
          <label class="d-flex label custom">{{ "From" | translate }}</label>
          <input
            type="date"
            nbInput
            fullWidth
            id="sendingDate"
            [(ngModel)]="dateFrom"
            placeholder="mm/dd/yyyy"
          />
        </div>
        <div class="col-sm-6">
          <label class="d-flex label custom">{{ "To" | translate }}</label>
          <input
            type="date"
            nbInput
            fullWidth
            id="sendingDate"
            [(ngModel)]="dateTo"
            placeholder="mm/dd/yyyy"
          />
          <ng-container *ngIf="dateTo !== '' && dateFrom !== ''">
            <p class="caption status-danger" *ngIf="dateTo < dateFrom">
              {{ "'Date to' can't be before 'Date from'" | translate }}!
            </p>
          </ng-container>
        </div>
      </div>
      <div class="row col-md-6 left">
        <button class="btn btn-custom-blue" (click)="search()">
          {{ "show" | translate }}
        </button>
        <button class="btn btn-custom-gray mx-2" (click)="reset()">
          {{ "Reset Date" | translate }}
        </button>
        <!-- start drop down menu -->
        <div class="btn-group nopadding" *ngIf="showExport">
          <button
            id="menubtn"
            (click)="toggleMenu()"
            type="button"
            class="btn white-color button-bk dropdown-toggle"
          >
            {{ "Export" | translate }}
          </button>
          <div class="dropdown-menu" id="dropdownmenu">
            <button
              class="dropdown-item fs-15 color-black text-decoration-none"
              (click)="downloadPdf()"
            >
              <img class="logo" src="assets/icons/download-pdf.svg" alt="pdf logo" loading="lazy">
            </button>
            <div class="dropdown-divider"></div>
            <button
              class="dropdown-item fs-15 color-black text-decoration-none"
              (click)="downloadExcel()"
            >
            <img class="logo" src="assets/icons/excel.svg" alt="pdf logo" loading="lazy">
          </button>
          </div>
        </div>
        <!-- end drop down menu -->
      </div>
    </div>
  </nb-card-header>
  <nb-card-body class="d-block overflow-auto" [dir]="'ltr'">
    <div class="p-2 ">
      <section class="spinner-container" *ngIf="showSpinner">
        <div class="spinner-border m-2" role="status">
          <span class="sr-only"> Loading... </span>
        </div>
      </section>
      <ng2-smart-table
        #table
        [settings]="settings"
        [source]="tableData"
        *ngIf="showTable"
      ></ng2-smart-table>
    </div>
  </nb-card-body>
</nb-card>
